import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack } from "@mui/material";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { InsHoldCanister, InsHoldCanisterEditCommand } from "../../interfaces/InsHoldCanister";
import { insHoldCanisterService } from "../../services/insHoldCanisterService";
import { successAlert } from "../../components/CustomModal";

interface IProps {
    insHoldCanister: InsHoldCanister;
    refresh: () => void;
}

const InsHoldCanisterEdit = ({ insHoldCanister, refresh }: IProps) => {
    const { handleSubmit, reset, control, setValue } = useForm();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setInsHoldCanister();
    };

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const onSubmit = (data: any) => {
        setLoading(true);
        data.canisterId = insHoldCanister?.canisterId;
        updateInsHoldCanister(data);
    };

    const setInsHoldCanister = () => {
        setValue("canisterId", insHoldCanister?.canisterId);
        setValue("orderId", insHoldCanister?.orderId);
        setValue("returnedLocationName", insHoldCanister?.returnedLocationName);
        setValue("canisterState", insHoldCanister?.canisterState);
        setValue("reason", insHoldCanister?.reason);
        setValue("note", insHoldCanister?.note);
    };

    const updateInsHoldCanister = async (command: InsHoldCanisterEditCommand) => {
        const result = await insHoldCanisterService.updateInsHoldCanister(command);
        setLoading(false);
        if (result?.code === 200) {
            successAlert("Succeeded in updating a InsHold Canister!");
            handleClose();
            refresh();
        }
    };

    return (
        <>
            <Link onClick={handleClickOpen}>Edit</Link>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Change Canister</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent style={{ width: "500px" }}>
                        <Stack spacing={2}>
                            <div>
                                <CustomInput
                                    required
                                    name="canisterId"
                                    placeholder="Canister ID"
                                    control={control}
                                    disabled
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    required
                                    name="orderId"
                                    placeholder="Order Number"
                                    control={control}
                                    disabled
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    required
                                    name="returnedLocationName"
                                    placeholder="Location"
                                    control={control}
                                    disabled
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    name="reason"
                                    placeholder="Reason"
                                    control={control}
                                    disabled
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomSelect
                                    required
                                    name="canisterState"
                                    placeholder="State"
                                    control={control}
                                    options={["Normal", "Damaged", "Used"].map((state) => {
                                        return { label: state, value: state };
                                    })}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    name="note"
                                    placeholder="Note"
                                    control={control}
                                    multiline
                                    rows={3}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit" disabled={loading}>
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default InsHoldCanisterEdit;
