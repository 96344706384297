import axios from "./customAxios";
import { AxiosRequestConfig } from "axios";
import config from "../config";
import delay from "../utils/delay";
import { errorAlert } from "../components/CustomModal";

const retries = config.network.error.retries;
const axiosInstance: any = axios(config.baseUrl);

const API = {
    get: async (url: string, conf?: AxiosRequestConfig) => {
        const result = await retry("get", url, conf);
        networkErrorModal(result?.code);
        return result;
    },
    post: async (url: string, data?: any, conf?: AxiosRequestConfig) => {
        const result = await retry("post", url, data, conf);
        networkErrorModal(result?.code);
        return result;
    },
    put: async (url: string, data?: any, conf?: AxiosRequestConfig) => {
        const result = await retry("put", url, data, conf);
        networkErrorModal(result?.code);
        return result;
    },
    delete: async (url: string, conf?: AxiosRequestConfig) => {
        const result = await retry("delete", url, conf);
        networkErrorModal(result?.code);
        return result;
    },
};

const retry = async (name: string, ...params: any) => {
    let i = 0;
    let result: any;
    do {
        i > 0 && (await delay(1000));
        result = await axiosInstance[name](...params);
        i++;
    } while (i <= retries && result?.code === "ERR_NETWORK");
    return result;
};

const networkErrorModal = (code: string) => {
    if (code === "ERR_NETWORK") {
        errorAlert(config.network.error.message);
    }
};

export { API };
