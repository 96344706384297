import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";

interface IProps {
    name: string;
    label?: string;
    required?: boolean;
    control: any;
    checked?: boolean;
    onChange?: (event: any) => void;
}
const CustomSwitch = ({ name, label, required, control }: IProps) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControl required={required}>
                             <FormControlLabel
                                    control={<Switch checked={value} onChange={onChange}/>}
                                    label={label}
                                    labelPlacement="top"
                                    />
                    </FormControl>
                )}
            />
        </>
    );
};

export default CustomSwitch;
