import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from "@mui/material";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import Cookies from "universal-cookie";

interface IProps {
    handleClick: () => void;
}

const Logout = ({ handleClick }: IProps) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        handleClick();
    };

    const onLogout = () => {
        const cookies = new Cookies();
        cookies.remove("userInfo");
        navigate("/");
    };

    return (
        <>
            {/* <MenuItem onClick={handleClickOpen}>Logout</MenuItem> */}
            <Button color="primary" fullWidth onClick={handleClickOpen}>
                <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                Sign out
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Exit</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure to exit?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onLogout}>Logout</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Logout;
