const config = {
    baseUrl: "https://service.ezbearspray.com/api",
    mqtt: {
        brokerUrl: "wss://service.ezbearspray.com/mqtt",
        username: "admin",
        password: "111111",
    },
    network: {
        error: {
            title: "Network error",
            message: "Network error, please contact the administrator.",
            retries: 0,
        },
        500: {
            default: {
                title: "Server error",
                message: "Server error, please contact the administrator.",
            },
            CanisterIsInvalid: {
                title: "Canister is invalid",
                message: "Canister is invalid, Please check the canister ID.",
            },
        },
    },
};

export default config;
