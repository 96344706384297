import { IMachinePositionCellCanister } from "../../../interfaces/IMachine";
import { CANISTER_TYPE, cellCanisterNumber, changeMachinePositionType, layerCanisterNumber } from "./operateUtil";

const operateSetDamagedUsedArea = {
    save: (command: any) => {
        return changeMachinePositionType({ ...command, positionType: CANISTER_TYPE.DAMAGED_USED });
    },
    setCanister: (
        canister: IMachinePositionCellCanister,
        checked: boolean,
        originCanister: IMachinePositionCellCanister
    ) => {
        canister.canisterType = checked ? CANISTER_TYPE.DAMAGED_USED : originCanister?.canisterType;
        return canister;
    },
    setSingleCanister: (canister: IMachinePositionCellCanister, originCanister: IMachinePositionCellCanister) => {
        canister.canisterType =
            canister.canisterType !== CANISTER_TYPE.DAMAGED_USED
                ? CANISTER_TYPE.DAMAGED_USED
                : originCanister?.canisterType;
        return canister;
    },
    getLayerCheck: (layerNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber && canister.canisterType === CANISTER_TYPE.DAMAGED_USED
            ).length === layerCanisterNumber
        );
    },
    getCellCheck: (layerNumber: number, cellNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber &&
                    canister.cellNumber === cellNumber &&
                    canister.canisterType === CANISTER_TYPE.DAMAGED_USED
            ).length === cellCanisterNumber
        );
    },
};

export default operateSetDamagedUsedArea;
