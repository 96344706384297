import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Checkbox, Container, FormControlLabel, Grid, Radio, Stack } from "@mui/material";
import { IMachinePosition, IMachinePositionCellCanister } from "../../../interfaces/IMachine";
import { machineService } from "../../../services/machineService";
import { CANISTER_TYPE, OPERATE, operateObj } from "./operateUtil";
import { useDispatch } from 'react-redux';


interface IOperation {
    type?: OPERATE;
    canisterIds?: Array<number>;
}

const MachineView = () => {
    const params = useParams();

    const [originPositions, setOriginPositions] = useState<Array<IMachinePosition>>([]);
    const [positions, setPositions] = useState<Array<IMachinePosition>>([]);
    const [canisters, setCanisters] = useState<Array<IMachinePositionCellCanister>>([]);
    const [operate, setOperate] = useState<IOperation>();
    const dispatch = useDispatch();
    useEffect(() => {
        params?.machineId && getMachinePositions(Number(params?.machineId));
    }, [params]);

    useEffect(() => {
        let newCanisters: Array<IMachinePositionCellCanister> = [];
        positions?.forEach((layer) => {
            layer.cells.forEach((cell) => {
                cell.canisters.forEach((canister) => {
                    canister.layoutNumber = layer.layoutNumber;
                    canister.cellNumber = cell.cellNumber;
                    newCanisters.push(canister);
                });
            });
        });
        setCanisters(newCanisters);
    }, [positions]);

    const getMachinePositions = async (machineId: number) => {
        const result = await machineService.getMachinePositions(machineId);
        if (result?.code === 200) {
            dispatch({type:"set_header",header:getTitle(result?.value)});
            const data = result?.value?.positions || [];
            const positions = JSON.parse(JSON.stringify(data));
            setPositions(positions);
            setOriginPositions(positions);
        }
    };

    const getTitle = (data: any) => {
        if(!data.machineNumber) return "";
        return data.locationName + " (" + data.machineNumber + ")";
    };

    const onOperate = (target: string) => {
        onCancel();
        setOperate({
            type: target as OPERATE,
            canisterIds: [],
        });
    };

    const onSave = async () => {
        const command = {
            machineId: Number(params?.machineId),
            canisterIds: operate?.canisterIds || [],
        };
        if (operate?.type && command.canisterIds?.length > 0) {
            const result = await operateObj[operate?.type].save(command);
            if (result) {
                setOperate(null as unknown as IOperation);
                getMachinePositions(command.machineId);
            }
        } else {
            setOperate(null as unknown as IOperation);
        }
    };

    const onCancel = () => {
        setOperate(null as unknown as IOperation);
        setPositions(JSON.parse(JSON.stringify(originPositions)));
    };

    const getLayerCheck = (layerNumber: number) => {
        return operate?.type ? operateObj[operate?.type].getLayerCheck(layerNumber, canisters) : false;
    };

    const getCellCheck = (layerNumber: number, cellNumber: number) => {
        return operate?.type ? operateObj[operate?.type].getCellCheck(layerNumber, cellNumber, canisters) : false;
    };

    const getOriginCanister = (canisterId: number) => {
        let originCanister = {};
        originPositions?.forEach((layer) => {
            layer.cells.forEach((cell) => {
                cell.canisters.forEach((canister) => {
                    if (canister.canisterId === canisterId) {
                        originCanister = canister;
                    }
                });
            });
        });
        return originCanister;
    };

    const onLayerCheck = (layerNumber: number, e: any) => {
        const newPositiions = positions?.map((layer) => {
            if (layer.layoutNumber === layerNumber) {
                layer.cells = layer.cells.map((cell) => {
                    cell.canisters = cell.canisters.map((canister) => {
                        return setCanister(canister, e.target.checked);
                    });
                    return cell;
                });
            }
            return layer;
        });
        setPositions(newPositiions);
    };

    const onCellCheck = (layerNumber: number, cellNumber: number, e: any) => {
        const newPositiions = positions?.map((layer) => {
            if (layer.layoutNumber === layerNumber) {
                layer.cells = layer.cells.map((cell) => {
                    if (cell.cellNumber === cellNumber) {
                        cell.canisters = cell.canisters.map((canister) => {
                            return setCanister(canister, e.target.checked);
                        });
                    }
                    return cell;
                });
            }
            return layer;
        });
        setPositions(newPositiions);
    };

    const onCanisterClick = (canisterId: number) => {
        const newPositiions = positions?.map((layer) => {
            layer.cells = layer.cells.map((cell) => {
                cell.canisters = cell.canisters.map((canister) => {
                    if (canister.canisterId === canisterId) {
                        canister = setSingleCanister(canister);
                    }
                    return canister;
                });
                return cell;
            });
            return layer;
        });
        setPositions(newPositiions);
    };

    const setCanister = (canister: IMachinePositionCellCanister, checked: boolean): IMachinePositionCellCanister => {
        const { type, canisterIds = [] } = operate || {};
        if (checked) {
            if (type === OPERATE.LOADING_SHELVES) {
                if (canister.canisterType === CANISTER_TYPE.NORMAL || canister.canisterType === null) {
                    canisterIds.push(canister?.canisterId);
                }
            } else {
                canisterIds.push(canister?.canisterId);
            }
        } else {
            const index = canisterIds.findIndex((item) => item === canister?.canisterId);
            index > -1 && canisterIds.splice(index, 1);
        }
        setOperate({ type, canisterIds });

        const originCanister = getOriginCanister(canister.canisterId) as IMachinePositionCellCanister;
        return type ? operateObj[type].setCanister(canister, checked, originCanister) : canister;
    };

    const setSingleCanister = (canister: IMachinePositionCellCanister): IMachinePositionCellCanister => {
        const { type, canisterIds = [] } = operate || {};
        canisterIds.push(canister?.canisterId);
        setOperate({ type, canisterIds });

        const originCanister = getOriginCanister(canister.canisterId) as IMachinePositionCellCanister;
        return type ? operateObj[type].setSingleCanister(canister, originCanister) : canister;
    };

    const onBack = () => {
        window.history.back();
    };

    const getClassName = (canister: any) =>{
        if(canister?.isDisabled) return "Disabled";
        return canister?.canisterType;
    };

    return (
        <>
            <Container maxWidth="lg" style={{ margin: "20px auto" }}>
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid xs={2} item>
                        <div className="machine-operates">
                            <div>
                                <Stack spacing={2}>
                                    <div>
                                        <Button onClick={onBack}>Back Machine List</Button>
                                    </div>
                                    {
                                        // operate?.type ? (
                                        //     <>
                                        //         <div>
                                        //             <Button variant="contained" onClick={onSave}>
                                        //                 Save
                                        //             </Button>
                                        //         </div>
                                        //         <div>
                                        //             <Button variant="outlined" color="error" onClick={onCancel}>
                                        //                 Cancel
                                        //             </Button>
                                        //         </div>
                                        //     </>
                                        // ) : (
                                        Object.values(OPERATE).map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    <FormControlLabel
                                                        control={<Radio checked={operate?.type === item} />}
                                                        label={item}
                                                        onClick={onOperate.bind(null, item)}
                                                    />
                                                    {/* <Button
                                                    key={key}
                                                    variant="contained"
                                                    onClick={onOperate.bind(null, item)}
                                                >
                                                    {item}
                                                </Button> */}
                                                    {operate?.type === item && (
                                                        <>
                                                            <Stack
                                                                direction="row"
                                                                spacing={1}
                                                                style={{ margin: "10px 0" }}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={onSave}
                                                                    style={{ width: "85px" }}
                                                                    disabled={
                                                                        !(
                                                                            operate?.canisterIds &&
                                                                            operate?.canisterIds.length > 0
                                                                        )
                                                                    }
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="error"
                                                                    onClick={onCancel}
                                                                    style={{ width: "85px" }}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Stack>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })
                                        // )
                                    }
                                </Stack>
                            </div>
                            <div className="machine-legend">
                                <div>
                                    <div className="normal"></div>
                                    <div>Normal</div>
                                </div>
                                <div>
                                    <div className="used"></div>
                                    <div>Damaged/Used</div>
                                </div>
                                <div>
                                    <div className="return"></div>
                                    <div>Return</div>
                                </div>
                                <div>
                                    <div className="disabled"></div>
                                    <div>Disabled</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <Card>
                            <div className="machine-view">
                                {positions?.map((layer, layerIndex) => {
                                    const names = ["1st", "2nd", "3rd"];
                                    return (
                                        <div key={Math.random()} className="machine-view-layer">
                                            <div>
                                                {operate?.type && (
                                                    <Checkbox
                                                        checked={getLayerCheck(layer?.layoutNumber)}
                                                        onClick={onLayerCheck.bind(null, layer?.layoutNumber)}
                                                    />
                                                )}
                                                <span>
                                                    {names[layer?.layoutNumber - 1] || `${layer?.layoutNumber}th`} Layer
                                                </span>
                                            </div>
                                            <div className="machine-view-cell">
                                                {layer?.cells?.map((cell, cellIndex) => {
                                                    return (
                                                        <div key={Math.random()}>
                                                            <div className="machine-view-canister">
                                                                {cell?.canisters?.map((canister) => {
                                                                    return (
                                                                        <div
                                                                            key={Math.random()}
                                                                            className="machine-view-canister-item"
                                                                            onClick={onCanisterClick.bind(
                                                                                null,
                                                                                canister?.canisterId
                                                                            )}
                                                                        >
                                                                            <div className={getClassName(canister)}>
                                                                                {!canister?.isEmpty && (
                                                                                    <img
                                                                                        alt="canister"
                                                                                        src="/assets/images/bear-spray.png"
                                                                                    />
                                                                                )}
                                                                                {/* {canister?.canisterId}
                                                                    {!canister?.isEmpty && "有"} */}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div>
                                                                {operate?.type && (
                                                                    <Checkbox
                                                                        checked={getCellCheck(
                                                                            layer?.layoutNumber,
                                                                            cell?.cellNumber
                                                                        )}
                                                                        onClick={onCellCheck.bind(
                                                                            null,
                                                                            layer?.layoutNumber,
                                                                            cell?.cellNumber
                                                                        )}
                                                                    />
                                                                )}
                                                                <span>cell {cell?.cellNumber}</span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default MachineView;
