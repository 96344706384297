import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from "@mui/material";
import { useState } from "react";
import { successAlert } from "../../components/CustomModal";
import { userService } from "../../services/userService";

interface IProps {
    userId: number;
    refresh: () => void;
}

const UserDelete = ({ userId, refresh }: IProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDelete = () => {
        deleteUser(userId);
    };

    const deleteUser = async (id: number) => {
        const result = await userService.deleteUser(id);
        if (result?.code === 200) {
            successAlert("Succeeded in deleting a user!");
            handleClose();
            refresh();
        }
    };

    return (
        <>
            <Link onClick={handleClickOpen}>Delete</Link>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure to delete?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={onDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserDelete;
