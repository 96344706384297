import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import CustomInput from "../../components/CustomInput";
import CustomInputPassword from "../../components/CustomInputPassword";
import { userService } from "../../services/userService";

const Login = () => {
    const navigate = useNavigate();
    const { handleSubmit, control } = useForm();

    const onSubmit = async (data: any) => {
        const result = await userService.login(data);
        if (result?.code === 200) {
            const cookies = new Cookies();
            cookies.set("userInfo", result?.value);
            navigate("/admin/user");
        }
    };

    return (
        <>
            <div className="login-content">
                <div>
                    <img alt="" src="/assets/images/login-chart.png" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form">
                        <div>Sign In</div>
                        <div>
                            <CustomInput
                                required
                                name="email"
                                placeholder="Enter Email"
                                control={control}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div>
                            <CustomInputPassword
                                required
                                name="password"
                                placeholder="Password"
                                control={control}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div>
                            <Button variant="contained" type="submit" style={{ width: "100%", height: "53px" }}>
                                Login
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;
