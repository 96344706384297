interface IProps {
    color: string;
    label: string;
}

const CustomTag = ({ color, label }: IProps) => {
    return (
        <div style={{ display: "flex" }}>
            <span
                style={{
                    width: "16px",
                    height: "16px",
                    display: "block",
                    margin: "0 8px",
                    borderRadius: "8px",
                    marginTop: "2px",
                    backgroundColor: color,
                }}
            ></span>
            <span style={{ color: color }}>{label}</span>
        </div>
    );
};

export default CustomTag;
