import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import config from "../config";
import { errorAlert } from "../components/CustomModal";
import Cookies from "universal-cookie";

const customAxios = {
    instance: (url: string) => {
        const as = axios.create({
            baseURL: url,
            timeout: 60000,
        });

        as.interceptors.request.use(
            (request) => requestHandler(request),
            (error) => errorHandler(error)
        );

        as.interceptors.response.use(
            (response) => responseHandler(response),
            (error) => errorHandler(error)
        );
        return as;
    },
};

const requestHandler = (request: InternalAxiosRequestConfig) => {
    const cookies = new Cookies();
    const userInfo = cookies.get("userInfo");
    request.headers.Authorization = "Bearer " + userInfo?.token;
    return request;
};

const responseHandler = (response: AxiosResponse) => {
    if (response.status === 401 || response.status === 403) {
        window.location.href = "/";
    } else {
        return response?.data;
    }
};

const errorHandler = (error: any) => {
    if (error.code === "ERR_NETWORK") {
        return {
            code: error.code,
        };
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.href = "/";
    } else if (error?.response?.status === 500) {
        const conf: any = config.network[500];
        const data = error?.response?.data || {};
        const messageCode = data["messageCode"] || data["MessageCode"];
        if (!conf[messageCode]) {
            const errorConf = conf.default;
            errorAlert(errorConf.message);
        }
    } else {
        return error?.response?.data;
    }
};

export default customAxios.instance;
