import InsHoldCanisters from "../pages/InsHoldCanisters";
import Layout from "../pages/Layout/index";
import Locations from "../pages/Locations";
import Login from "../pages/Login";
import Machine from "../pages/Machine";
import MachineView from "../pages/Machine/MachineView";
import NotFound from "../pages/NotFound";
import User from "../pages/User";

const routes = [
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/admin",
        element: <Layout />,
        children: [
            {
                title: "User",
                path: "/admin/user",
                element: <User />,
            },
            {
                title: "Machine",
                path: "/admin/machine",
                element: <Machine />,
            },
            {
                path: "/admin/machine/view/:machineId",
                element: <MachineView />,
            },
            {
                title: "InsHold Canisters",
                path: "/admin/inshold-canisters",
                element: <InsHoldCanisters />,
            },
            {
                title: "Locations",
                path: "/admin/locations",
                element: <Locations />,
            },
        ],
    },
    {
        path: "*",
        element: <NotFound />,
    },
];

export default routes;
