import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import mqtt from "mqtt";
import config from "../config";

export const Topics = {
    NOTICE_MACHINE_STATE: `notice/machine/state`,
};

export interface IMQTTState {
    topic?: string;
    message?: string;
}

export const MQTTContext = createContext<(IMQTTState | React.Dispatch<any>)[]>([]);

export const MQTTProvider = ({ children }: any) => {
    const initialState: IMQTTState = {};
    const reducer = (state: any, action: any): IMQTTState => {
        return action.type === "message" ? { ...state, ...action.value } : {};
    };
    const [controller, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

    useEffect(() => {
        // setTimeout(() => {
        //     dispatch({ type: "message", value: { topic: Topics.NOTICE_MACHINE_STATE, message: "[94397]" } });
        // }, 10000);

        // setTimeout(() => {
        //     dispatch({ type: "message", value: { topic: Topics.NOTICE_MACHINE_STATE, message: "[]" } });
        // }, 20000);

        MQTTClient();

        setTimeout(() => {
            const machineId = localStorage.getItem("machineId") || "";
            const oldMachineId = localStorage.getItem("oldMachineId") || "";
            if (oldMachineId !== machineId) {
                localStorage.setItem("oldMachineId", machineId);
                window.location.reload();
            }
        }, 1000);
    }, []);

    const MQTTClient = () => {
        const options = {
            keepalive: 30,
            username: config.mqtt.username,
            password: config.mqtt.password,
            reconnectPeriod: 0,
        };

        const client = mqtt.connect(config.mqtt.brokerUrl, options);

        client.on("connect", (e) => {
            console.log("MQTT Connected", e);
        });

        client.subscribe({ [Topics.NOTICE_MACHINE_STATE]: { qos: 2 } }, function (err) {
            if (err) {
                console.log("MQTT Subscribe Error: ", err);
                setTimeout(() => {
                    MQTTClient();
                }, 5000);
            }
        });

        client.on("message", (topic, message) => {
            dispatch({ type: "message", value: { topic, message: message.toString() } });
        });

        client.on("reconnect", () => {
            console.log("MQTT Reconnect");
        });

        client.on("disconnect", (e) => {
            console.log("MQTT Disconnect", e);
            setTimeout(() => {
                MQTTClient();
            }, 5000);
        });
    };

    return <MQTTContext.Provider value={value}>{children}</MQTTContext.Provider>;
};

export const useMQTTController = () => {
    const context = useContext(MQTTContext);
    if (!context) {
        throw new Error("useMQTTController should be used inside the MQTTProvider.");
    }
    return context;
};
