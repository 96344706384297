import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";

interface IProps {
    name: string;
    placeholder?: string;
    required?: boolean;
    control: any;
    style?: React.CSSProperties;
}

const CustomInputPassword = ({ name, placeholder, required, control, style }: IProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControl style={style} variant="outlined" required={required}>
                        <InputLabel htmlFor="outlined-adornment-password">{placeholder}</InputLabel>
                        <OutlinedInput
                            onChange={onChange}
                            value={value}
                            autoComplete="off"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                )}
            />
        </>
    );
};

export default CustomInputPassword;
