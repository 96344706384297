import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const NotFound = () => {
    const navigate = useNavigate();

    const onClick = () => {
        const cookies = new Cookies();
        const userInfo = cookies.get("userInfo");
        if (userInfo?.token) {
            navigate("/admin/user");
        } else {
            navigate("/");
        }
    };

    return (
        <>
            <img
                alt=""
                src="/assets/images/notfound.png"
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    width: "1000px",
                }}
                onClick={onClick}
            />
        </>
    );
};

export default NotFound;
