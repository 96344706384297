import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CustomInput from "../../components/CustomInput";
import { successAlert } from "../../components/CustomModal";
import { IMachine, IMachineAddCommand, IMachineUpdateCommand } from "../../interfaces/IMachine";
import { machineService } from "../../services/machineService";
import CustomSelect from "../../components/CustomSelect";
import { locationService } from "../../services/locationService";
import { ILocation } from "../../interfaces/ILocation";
import CustomSwitch from "../../components/CustomSwitch";

interface IProps {
    isEdit?: boolean;
    machine?: IMachine;
    refresh: () => void;
}

const MachineAdd = ({ isEdit, machine, refresh }: IProps) => {
    const { handleSubmit, reset, control, setValue } = useForm();

    const [open, setOpen] = useState<boolean>(false);
    const [locations, setLocations] = useState<Array<ILocation>>([]);

    const handleClickOpen = () => {
        setOpen(true);
        getLocations();
        isEdit && setMachine();
    };

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const onSubmit = (data: any) => {
        data.machineId = machine?.id;
        isEdit ? updateMachine(data) : addMachine(data);
    };

    const setMachine = () => {
        setValue("locationID", machine?.locationID);
        setValue("machineNumber", machine?.machineNumber);
        setValue("note", machine?.note);
        setValue("isDisabled", machine?.isDisabled);
    };

    const addMachine = async (machineAddCommand: IMachineAddCommand) => {
        const result = await machineService.addMachine(machineAddCommand);
        if (result?.code === 200) {
            successAlert(`Succeeded in creating a machine!`);
            handleClose();
            refresh();
        }
    };

    const updateMachine = async (machineUpdateCommand: IMachineUpdateCommand) => {
        const result = await machineService.updateMachine(machineUpdateCommand);
        if (result?.code === 200) {
            successAlert("Succeeded in updating a machine!");
            handleClose();
            refresh();
        }
    };

    const getLocations = async () => {
        const result = await locationService.getLocations();
        if (result?.code === 200) {
            setLocations(result?.value);
        }
    };

    return (
        <>
            {isEdit ? (
                <Link onClick={handleClickOpen}>Edit</Link>
            ) : (
                <div style={{ marginBottom: "20px" }}>
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={handleClickOpen}
                    >
                        Create Machine
                    </Button>
                </div>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEdit ? "Edit" : "Create"} Machine</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent style={{ width: "500px" }}>
                        <Stack spacing={2}>
                            <div>
                                <CustomSelect
                                    required
                                    name="locationID"
                                    placeholder="Location"
                                    control={control}
                                    options={locations.map((location) => {
                                        return { label: location.name, value: location.id };
                                    })}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    required
                                    name="machineNumber"
                                    placeholder="Machine Number"
                                    control={control}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomSwitch
                                    required
                                    name="isDisabled"
                                    label="Disabled"
                                    control={control}                        
                                    />
                            </div>
                            <div>
                                <CustomInput
                                    name="note"
                                    placeholder="Note (This information will be shown when the machine is disabled)"
                                    multiline
                                    rows={5}
                                    control={control}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default MachineAdd;
