import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CustomInput from "../../components/CustomInput";
import { userService } from "../../services/userService";
import { IUser, IUserAddCommand, IUserUpdateCommand } from "../../interfaces/IUser";
import { successAlert } from "../../components/CustomModal";

interface IProps {
    isEdit?: boolean;
    userId?: number;
    refresh: () => void;
}

const defaultValues = {
    firstName: "",
    lastName: "",
    // gender: "male",
    // dateOfBirth: dayjs(),
    phoneNumber: "",
    email: "",
};

const UserAdd = ({ isEdit, userId, refresh }: IProps) => {
    const { handleSubmit, reset, control, setValue } = useForm({ defaultValues });

    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
        isEdit && userId && getUser(userId);
    };

    const handleClose = () => {
        reset();
        setOpen(false);
    };

    const onSubmit = (data: any) => {
        // data.gender = data.gender || "male";
        // data.dateOfBirth = (data.dateOfBirth || dayjs()).format("MM/DD/YYYY");
        data.id = userId;
        // console.log("data: ", data);
        isEdit ? updateUser(data) : addUser(data);
    };

    const getUser = async (id: number) => {
        const result = await userService.getUser(id);
        if (result?.code === 200) {
            const user: IUser = result?.value;
            console.log("user: ", user);

            setValue("firstName", user.firstName);
            setValue("lastName", user.lastName);
            // setValue("gender", user.gender);
            // setValue("dateOfBirth", dayjs(user.dateOfBirth));
            setValue("phoneNumber", user.phoneNumber);
            setValue("email", user.email);
        }
    };

    const addUser = async (userAddCommand: IUserAddCommand) => {
        const result = await userService.addUser(userAddCommand);
        if (result?.code === 200) {
            successAlert(`Succeeded in creating a user!`);
            handleClose();
            refresh();
        }
    };

    const updateUser = async (userUpdateCommand: IUserUpdateCommand) => {
        const result = await userService.updateUser(userUpdateCommand);
        if (result?.code === 200) {
            successAlert("Succeeded in updating a user!");
            handleClose();
            refresh();
        }
    };

    return (
        <>
            {isEdit ? (
                <Link onClick={handleClickOpen}>Edit</Link>
            ) : (
                <div style={{ marginBottom: "20px" }}>
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={handleClickOpen}
                    >
                        Create User
                    </Button>
                </div>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create User</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent style={{ width: "500px" }}>
                        <Stack spacing={2}>
                            <div>
                                <Stack spacing={2} direction="row">
                                    <CustomInput
                                        required
                                        name="firstName"
                                        placeholder="First Name"
                                        control={control}
                                        style={{ width: "242px" }}
                                    />
                                    <CustomInput
                                        required
                                        name="lastName"
                                        placeholder="Last Name"
                                        control={control}
                                        style={{ width: "242px" }}
                                    />
                                </Stack>
                            </div>
                            {/* <div>
                                <CustomRadioGroup
                                    required
                                    name="gender"
                                    placeholder="Gender"
                                    control={control}
                                    radios={[
                                        { label: "Female", value: "female" },
                                        { label: "Male", value: "male" },
                                    ]}
                                />
                            </div> */}
                            {/* <div>
                                <CustomDatePicker
                                    name="dateOfBirth"
                                    placeholder="Date Of Birth"
                                    control={control}
                                    className="full-width"
                                />
                            </div> */}
                            <div>
                                <CustomInput
                                    required
                                    name="phoneNumber"
                                    type="phoneNumber"
                                    placeholder="Phone Number"
                                    control={control}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            {!isEdit && (
                                <>
                                    <div>
                                        <CustomInput
                                            required
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            control={control}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default UserAdd;
