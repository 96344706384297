import { IMachinePositionCellCanister } from "../../../interfaces/IMachine";
import { CANISTER_TYPE, cellCanisterNumber, changeMachinePositionFreeState, layerCanisterNumber } from "./operateUtil";

const operateLoadingShelves = {
    save: (command: any) => {
        return changeMachinePositionFreeState({ ...command, isFree: false });
    },
    setCanister: (
        canister: IMachinePositionCellCanister,
        checked: boolean,
        originCanister: IMachinePositionCellCanister
    ) => {
        if (canister.canisterType === CANISTER_TYPE.NORMAL || canister.canisterType === null) {
            canister.isEmpty = checked ? false : originCanister?.isEmpty;
        }
        return canister;
    },
    setSingleCanister: (canister: IMachinePositionCellCanister, originCanister: IMachinePositionCellCanister) => {
        canister.isEmpty = canister.isEmpty ? false : originCanister?.isEmpty;
        return canister;
    },
    getLayerCheck: (layerNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber &&
                    (canister.canisterType === CANISTER_TYPE.NORMAL || canister.canisterType === null) &&
                    !canister.isEmpty
            ).length ===
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber &&
                    (canister.canisterType === CANISTER_TYPE.NORMAL || canister.canisterType === null)
            ).length
        );
    },
    getCellCheck: (layerNumber: number, cellNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber && canister.cellNumber === cellNumber && !canister.isEmpty
            ).length === cellCanisterNumber
        );
    },
};

export default operateLoadingShelves;
