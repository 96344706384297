import { IMachinePositionCellCanister } from "../../../interfaces/IMachine";
import { CANISTER_TYPE, cellCanisterNumber, changeMachinePositionDisabledState, layerCanisterNumber } from "./operateUtil";

const operateDisablePosition = {
    save: (command: any) => {
        return changeMachinePositionDisabledState({ ...command, isDisabled: true });
    },
    setCanister: (
        canister: IMachinePositionCellCanister,
        checked: boolean,
        originCanister: IMachinePositionCellCanister
    ) => {
        canister.isDisabled = checked ? true : originCanister?.isDisabled;
        return canister;
    },
    setSingleCanister: (canister: IMachinePositionCellCanister, originCanister: IMachinePositionCellCanister) => {
        canister.isDisabled = !originCanister.isDisabled;
        return canister;
    },
    getLayerCheck: (layerNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber && canister.isDisabled
            ).length === layerCanisterNumber
        );
    },
    getCellCheck: (layerNumber: number, cellNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber && canister.cellNumber === cellNumber && canister.isDisabled
            ).length === cellCanisterNumber
        );
    },
};

export default operateDisablePosition;
