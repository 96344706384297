import { useRef, useState } from "react";
import { Box, Button, Divider, Hidden, List, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import Cookies from "universal-cookie";
import Logout from "../../Logout";
import ChangePassword from "../../ChangePassword";

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

function HeaderUserbox() {
    const cookies = new Cookies();
    const user = cookies.get("userInfo");

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
                <Hidden mdDown>
                    <UserBoxText>
                        <UserBoxLabel variant="body1">
                            {user?.firstName} {user?.lastName}
                        </UserBoxLabel>
                        {/* <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription> */}
                    </UserBoxText>
                </Hidden>
                <Hidden smDown>
                    <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
                </Hidden>
            </UserBoxButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuUserBox sx={{ minWidth: 210 }} display="flex">
                    {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
                    <UserBoxText>
                        <UserBoxLabel variant="body1">
                            {user?.firstName} {user?.lastName}
                        </UserBoxLabel>
                    </UserBoxText>
                </MenuUserBox>
                <Divider sx={{ mb: 0 }} />
                <List sx={{ p: 1 }} component="nav">
                    <ChangePassword handleClick={handleClose} />
                </List>
                <Divider />
                <Box sx={{ m: 1 }}>
                    <Logout handleClick={handleClose} />
                </Box>
            </Popover>
        </>
    );
}

export default HeaderUserbox;
