import { useState } from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    Stack,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Cookies from "universal-cookie";
import CustomInputPassword from "../../components/CustomInputPassword";
import { successAlert } from "../../components/CustomModal";
import { IUserChangePasswordCommand } from "../../interfaces/IUser";
import { userService } from "../../services/userService";

interface IProps {
    handleClick: () => void;
}

const ChangePassword = ({ handleClick }: IProps) => {
    const { handleSubmit, reset, control } = useForm();

    const [open, setOpen] = useState<boolean>(false);

    const onClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        reset();
        setOpen(false);
        handleClick();
    };

    const onSubmit = (data: any) => {
        changePassword(data);
    };

    const changePassword = async (userChangePasswordCommand: IUserChangePasswordCommand) => {
        const cookies = new Cookies();
        const user = cookies.get("userInfo");

        userChangePasswordCommand.userId = user.id;
        const result = await userService.changePassword(userChangePasswordCommand);
        if (result?.code === 200) {
            successAlert("Succeeded in changing the password!");
            handleClose();
        }
    };

    return (
        <>
            <ListItem component={Button} onClick={onClick}>
                <VpnKeyIcon fontSize="small" />
                <ListItemText primary="Change Password" style={{ marginLeft: "10px" }} />
            </ListItem>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Change Password</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent style={{ width: "500px" }}>
                        <Stack spacing={3}>
                            <div>
                                <CustomInputPassword
                                    required
                                    name="oldPassword"
                                    placeholder="Old Password"
                                    control={control}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomInputPassword
                                    required
                                    name="password"
                                    placeholder="New Password"
                                    control={control}
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <CustomInputPassword
                                    required
                                    name="confirmedPassword"
                                    placeholder="Confirm Password"
                                    control={control}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default ChangePassword;
