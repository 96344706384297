import { useEffect, useState } from "react";
import { Card, Container, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import Decimal from "decimal.js";
import CustomTable, { IColumn } from "../../components/CustomTable";
import PageTitleWrapper from "../../components/PageTitleWrapper";
import { ILocation } from "../../interfaces/ILocation";
import { locationService } from "../../services/locationService";
import LocationAdd from "./LocationAdd";

const Locations = () => {
    const [locations, setLocations] = useState<Array<ILocation>>([]);

    useEffect(() => {
        getLocations();
    }, []);

    const refresh = () => {
        getLocations();
    };

    const getLocations = async () => {
        const result = await locationService.getLocations();
        if (result?.code === 200) {
            setLocations(result?.value);
        }
    };

    const columns: Array<IColumn> = [
        {
            title: "Action",
            width: 80,
            render(data) {
                return (
                    <Stack direction="row" spacing={2}>
                        <LocationAdd isEdit location={data} refresh={refresh} />
                        {/* <LocationDelete locationId={data?.id} refresh={refresh} /> */}
                    </Stack>
                );
            },
        },
        {
            title: "EZ Location",
            dataKey: "name",
            width: 200,
        },
        {
            title: "Resort Tax",
            width: 100,
            render(data) {
                return <>{new Decimal(data.resortTax).mul(100).toNumber()}%</>;
            },
        },
        {
            title: "County Tax",
            width: 100,
            render(data) {
                return <>{new Decimal(data.countyTax).mul(100).toNumber()}%</>;
            },
        },
        {
            title: "State Tax",
            width: 100,
            render(data) {
                return <>{new Decimal(data.stateTax).mul(100).toNumber()}%</>;
            },
        },
        {
            title: "Total Tax",
            width: 100,
            render(data) {
                return <>{new Decimal(data.totalTax).mul(100).toNumber()}%</>;
            },
        },
        {
            title: "Season",
            width: 330,
            render(data) {
                const seasonStartDate = moment.utc(data.seasonStartDate || "");
                const seasonEndDate = moment.utc(data.seasonEndDate || "");

                const start = seasonStartDate.isValid()
                    ? seasonStartDate.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                    : "";
                const end = seasonEndDate.isValid()
                    ? seasonEndDate.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                    : "";
                return (
                    <>
                        {start} - {end}
                    </>
                );
            },
        },
        {
            title: "Max Rentable Days",
            dataKey: "maxRentableDays",
            width: 150,
        },
        {
            title: "Max Rentable Quantity",
            dataKey: "maxRentableQuantity",
            width: 180,
        },
        {
            title: "Full Price Amount",
            width: 140,
            render(data) {
                return <>${data.fullPriceAmount}</>;
            },
        },
        {
            title: "Used Price",
            width: 80,
            render(data) {
                return <>${data.usedPrice}</>;
            },
        },
        {
            title: "Damaged Price",
            width: 120,
            render(data) {
                return <>${data.damagedPrice}</>;
            },
        },
        {
            title: "Late Fee Per Day",
            width: 120,
            render(data) {
                return <>${data.lateFeePerDay}</>;
            },
        },
        {
            title: "EZ Payment Name",
            dataKey: "ezPaymentName",
            width: 200,
        },
        {
            title: "EZ Stock Asset Id",
            dataKey: "ezStockAssetId",
            width: 130,
        },
        {
            title: "EZ Guest ID",
            dataKey: "ezGuestID",
            width: 100,
        },
        {
            title: "State",
            dataKey: "state",
            width: 200,
        },
        {
            title: "City",
            dataKey: "city",
            width: 200,
        },
        {
            title: "Post Code",
            dataKey: "postCode",
            width: 200,
        },
        {
            title: "Address1",
            dataKey: "address1",
            width: 200,
        },
        {
            title: "Address2",
            dataKey: "address2",
            width: 200,
        },
        {
            title: "Updated Date",
            width: 160,
            render: (data) => {
                const date = moment.utc(data.updateDate || "");
                return (
                    <>
                        {date.isValid()
                            ? date.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Created Date",
            width: 160,
            render: (data) => {
                const date = moment.utc(data.createDate || "");
                return (
                    <>
                        {date.isValid()
                            ? date.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                            : ""}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <PageTitleWrapper>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            Locations
                        </Typography>
                        {/* <Typography variant="subtitle2">These are your recent transactions</Typography> */}
                    </Grid>
                    <Grid item>
                        <LocationAdd refresh={refresh} />
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <div className="horizontal-scroll-table">
                                <CustomTable columns={columns} dataSource={locations} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Locations;
