import { API } from "../api/httpClient";
import Mockjs from "mockjs";
import { ILocationAddCommand, ILocationEditCommand } from "../interfaces/ILocation";

export const locationService = {
    getLocations: async () => {
        return await API.get(`/locations`);
    },
    addLocation: async (command: ILocationAddCommand) => {
        return await API.post(`/locations`, command);
    },
    updateLocation: async (command: ILocationEditCommand) => {
        return await API.put(`/locations/${command.locationId}`, command);
    },
    deleteLocation: async (id: number) => {
        return await Mockjs.mock({ code: 200 });
    },
    getEZLocations: async () => {
        return await API.get(`/locations/creatable-locations`);
    },
    getEZPaymentMethods: async () => {
        return await API.get(`/locations/payment-methods`);
    },
};
