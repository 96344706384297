import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Link, Modal, Stack } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Decimal from "decimal.js";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { locationService } from "../../services/locationService";
import { IEZLocation, ILocation, ILocationAddCommand, ILocationEditCommand } from "../../interfaces/ILocation";
import { successAlert } from "../../components/CustomModal";
import CustomDateTimePicker from "../../components/CustomDateTimePicker";
import moment from "moment";

interface IProps {
    isEdit?: boolean;
    location?: ILocation;
    refresh: () => void;
}

const initData = {
    maxRentableDays: 21,
    maxRentableQuantity: 5,
    fullPriceAmount: 60,
    usedPrice: 55,
    damagedPrice: 55,
    lateFeePerDay: 7,
    ezStockAssetId: 1,
    ezGuestID: 1289270,
};

const LocationAdd = ({ isEdit, location, refresh }: IProps) => {
    const { handleSubmit, reset, control, setValue } = useForm();

    const [open, setOpen] = useState<boolean>(false);
    const [constraintStart, setConstraintStart] = useState<any>();
    const [constraintEnd, setConstraintEnd] = useState<any>();
    const [EZLocations, setEZLocations] = useState<Array<IEZLocation>>();
    const [EZPaymentMethods, setEZPaymentMethods] = useState<Array<string>>();
    const [locationId, setLocationId] = useState<number>();
    const [resortTax, setResortTax] = useState<number>(0);
    const [countyTax, setCountyTax] = useState<number>(0);
    const [stateTax, setStateTax] = useState<number>(0);
    const [fullPriceAmount, setFullPriceAmount] = useState<number>(0);
    const [fieldErrors, setFieldErrors] = useState<any>({});

    const handleClickOpen = () => {
        getEZLocations();
        getEZPaymentMethods();
        setOpen(true);
        isEdit ? setLocation() : initLocation();
    };

    const handleClose = () => {
        reset();
        setOpen(false);
        setConstraintStart(null);
        setConstraintEnd(null);
    };

    const onError = (name: string, error: boolean, message?: string) => {
        if (error) {
            fieldErrors[name] = message;
        } else {
            delete fieldErrors[name];
        }
        setFieldErrors(fieldErrors);
    };

    const onSubmit = (data: any) => {
        console.log("fieldErrors: ", fieldErrors);
        if (Object.keys(fieldErrors).length === 0) {
            data.locationId = locationId;
            data.resortTax = new Decimal(data.resortTax).div(100).toNumber();
            data.countyTax = new Decimal(data.countyTax).div(100).toNumber();
            data.stateTax = new Decimal(data.stateTax).div(100).toNumber();
            data.totalTax = new Decimal(data.totalTax).div(100).toNumber();
            data.seasonStartDate = moment
                .utc(data.seasonStartDate.format("MM/DD/YYYY hh:mm a"))
                .utcOffset(new Date().getTimezoneOffset() / 60)
                .format("MM/DD/YYYY hh:mm a");
            data.seasonEndDate = moment
                .utc(data.seasonEndDate.format("MM/DD/YYYY hh:mm a"))
                .utcOffset(new Date().getTimezoneOffset() / 60)
                .format("MM/DD/YYYY hh:mm a");
            console.log(data.seasonEndDate);
            isEdit ? updateLocation(data) : addLocation(data);
        }
    };

    const addLocation = async (command: ILocationAddCommand) => {
        const result = await locationService.addLocation(command);
        if (result?.code === 200) {
            successAlert(`Succeeded in creating a location!`);
            handleClose();
            refresh();
        }
    };

    const updateLocation = async (command: ILocationEditCommand) => {
        const result = await locationService.updateLocation(command);
        if (result?.code === 200) {
            successAlert(`Succeeded in updating a location!`);
            handleClose();
            refresh();
        }
    };

    const initLocation = () => {
        setFullPriceAmount(initData.fullPriceAmount);
        Object.entries(initData).forEach((item) => {
            setValue(item[0], item[1]);
        });
    };

    const setLocation = () => {
        setLocationId(location?.id);
        setValue("name", location?.name);
        setValue("state", location?.state);
        setValue("city", location?.city);
        setValue("postCode", location?.postCode);
        setValue("address1", location?.address1);
        setValue("address2", location?.address2);
        setValue("resortTax", new Decimal(location?.resortTax || 0).mul(100).toNumber());
        setValue("countyTax", new Decimal(location?.countyTax || 0).mul(100).toNumber());
        setValue("stateTax", new Decimal(location?.stateTax || 0).mul(100).toNumber());
        setValue("totalTax", new Decimal(location?.totalTax || 0).mul(100).toNumber());

        const seasonStartDate = moment.utc(location?.seasonStartDate || "");
        const seasonEndDate = moment.utc(location?.seasonEndDate || "");
        const start = seasonStartDate.isValid()
            ? seasonStartDate.utcOffset(new Date().getTimezoneOffset() / -60)
            : null;
        const end = seasonEndDate.isValid() ? seasonEndDate.utcOffset(new Date().getTimezoneOffset() / -60) : null;
        setValue("seasonStartDate", start);
        setValue("seasonEndDate", end);

        setValue("maxRentableDays", location?.maxRentableDays || initData.maxRentableDays);
        setValue("maxRentableQuantity", location?.maxRentableQuantity || initData.maxRentableQuantity);
        setValue("fullPriceAmount", location?.fullPriceAmount || initData.fullPriceAmount);
        setFullPriceAmount(location?.fullPriceAmount || initData.fullPriceAmount);
        setValue("usedPrice", location?.usedPrice || initData.usedPrice);
        setValue("damagedPrice", location?.damagedPrice || initData.damagedPrice);
        setValue("lateFeePerDay", location?.lateFeePerDay || initData.lateFeePerDay);
        setValue("ezPaymentName", location?.ezPaymentName);
        setValue("ezStockAssetId", location?.ezStockAssetId || initData.ezStockAssetId);
        setValue("ezGuestID", location?.ezGuestID || initData.ezGuestID);

        setResortTax(new Decimal(location?.resortTax || 0).mul(100).toNumber());
        setCountyTax(new Decimal(location?.countyTax || 0).mul(100).toNumber());
        setStateTax(new Decimal(location?.stateTax || 0).mul(100).toNumber());
    };

    const getEZLocations = async () => {
        const result = await locationService.getEZLocations();
        if (result?.code === 200) {
            setEZLocations(result.value);
        }
    };

    const getEZPaymentMethods = async () => {
        const result = await locationService.getEZPaymentMethods();
        if (result?.code === 200) {
            setEZPaymentMethods(result.value);
        }
    };

    const onEZLocationChange = (e: any) => {
        const value = e.target.value;
        const selected = EZLocations?.find((EZLocation) => EZLocation.name === value);
        setLocationId(selected?.id);
        setValue("state", selected?.state);
        setValue("city", selected?.city);
        setValue("address1", selected?.address1);
        setValue("address2", selected?.address2);
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow:
            "0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)",
        // height: "calc(100vh - 80px)",
    };

    return (
        <>
            {isEdit ? (
                <Link onClick={handleClickOpen}>Edit</Link>
            ) : (
                <div style={{ marginBottom: "20px" }}>
                    <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                        onClick={handleClickOpen}
                    >
                        Create Location
                    </Button>
                </div>
            )}
            <Modal open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ ...style, width: 1000 }}>
                        <DialogTitle>{isEdit ? "Edit" : "Create"} Location</DialogTitle>
                        <DialogContent
                            style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto", paddingTop: "12px" }}
                        >
                            <Stack spacing={2}>
                                <div>
                                    {isEdit ? (
                                        <CustomInput
                                            required
                                            name="name"
                                            placeholder="EZ Location"
                                            disabled
                                            control={control}
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                        <CustomSelect
                                            required
                                            name="name"
                                            placeholder="EZ Location"
                                            control={control}
                                            options={EZLocations?.map((EZLocation) => {
                                                return { label: EZLocation.name, value: EZLocation.name };
                                            })}
                                            onSelectChange={onEZLocationChange}
                                        />
                                    )}
                                </div>
                                <div>
                                    <CustomInput
                                        name="state"
                                        placeholder="State"
                                        control={control}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <CustomInput
                                        name="city"
                                        placeholder="City"
                                        control={control}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <CustomInput
                                        name="postCode"
                                        placeholder="Post Code"
                                        control={control}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <CustomInput
                                        name="address1"
                                        placeholder="Address 1"
                                        control={control}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <CustomInput
                                        name="address2"
                                        placeholder="Address 2"
                                        control={control}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <fieldset>
                                    <legend>Tax</legend>
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{ marginLeft: "-12px", width: "calc(100% + 12px)" }}
                                    >
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="resortTax"
                                                placeholder="Resort Tax"
                                                control={control}
                                                endAdornment="%"
                                                inputType="number"
                                                min={0}
                                                max={100}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onInputChange={(e) => {
                                                    const value = Number(e.target.value || 0);
                                                    setResortTax(value);
                                                    setValue(
                                                        "totalTax",
                                                        new Decimal(value).add(countyTax).add(stateTax).toNumber()
                                                    );
                                                }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="countyTax"
                                                placeholder="County Tax"
                                                control={control}
                                                endAdornment="%"
                                                inputType="number"
                                                min={0}
                                                max={100}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onInputChange={(e) => {
                                                    const value = Number(e.target.value || 0);
                                                    setCountyTax(value);
                                                    setValue(
                                                        "totalTax",
                                                        new Decimal(resortTax).add(value).add(stateTax).toNumber()
                                                    );
                                                }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="stateTax"
                                                placeholder="State Tax"
                                                control={control}
                                                endAdornment="%"
                                                inputType="number"
                                                min={0}
                                                max={100}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onInputChange={(e) => {
                                                    const value = Number(e.target.value || 0);
                                                    setStateTax(value);
                                                    setValue(
                                                        "totalTax",
                                                        new Decimal(resortTax).add(countyTax).add(value).toNumber()
                                                    );
                                                }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="totalTax"
                                                placeholder="Total Tax"
                                                control={control}
                                                disabled
                                                endAdornment="%"
                                                inputType="number"
                                                min={0}
                                                max={100}
                                                precision={2}
                                                style={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                                <fieldset>
                                    <legend>Constraint</legend>
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{ marginLeft: "-12px", width: "calc(100% + 12px)" }}
                                    >
                                        <Grid item md={3}>
                                            <CustomDateTimePicker
                                                name="seasonStartDate"
                                                placeholder="Season Start Date *"
                                                control={control}
                                                maxDate={constraintEnd}
                                                onDateChange={setConstraintStart}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomDateTimePicker
                                                name="seasonEndDate"
                                                placeholder="Season End Date *"
                                                control={control}
                                                minDate={constraintStart}
                                                onDateChange={setConstraintEnd}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="maxRentableDays"
                                                placeholder="Max Rentable Days"
                                                control={control}
                                                inputType="number"
                                                min={0}
                                                max={100}
                                                precision={0}
                                                style={{ width: "100%" }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="maxRentableQuantity"
                                                placeholder="Max Rentable Quantity"
                                                control={control}
                                                inputType="number"
                                                min={0}
                                                max={100}
                                                precision={0}
                                                style={{ width: "100%" }}
                                                onError={onError}
                                            />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                                <fieldset>
                                    <legend>Pricing</legend>
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{ marginLeft: "-12px", width: "calc(100% + 12px)" }}
                                    >
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="fullPriceAmount"
                                                placeholder="Full Price Amount"
                                                control={control}
                                                inputType="number"
                                                startAdornment="$"
                                                min={0}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onInputChange={(e) => {
                                                    setFullPriceAmount(e.target.value);
                                                }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="usedPrice"
                                                placeholder="Used Price"
                                                control={control}
                                                inputType="number"
                                                startAdornment="$"
                                                min={0}
                                                max={fullPriceAmount}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="damagedPrice"
                                                placeholder="Damaged Price"
                                                control={control}
                                                inputType="number"
                                                startAdornment="$"
                                                min={0}
                                                max={fullPriceAmount}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onError={onError}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="lateFeePerDay"
                                                placeholder="Late Fee Per Day"
                                                control={control}
                                                inputType="number"
                                                startAdornment="$"
                                                min={0}
                                                max={fullPriceAmount}
                                                precision={2}
                                                style={{ width: "100%" }}
                                                onError={onError}
                                            />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                                <fieldset>
                                    <legend>EZ Rent Out</legend>
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{ marginLeft: "-12px", width: "calc(100% + 12px)" }}
                                    >
                                        <Grid item md={6}>
                                            <CustomSelect
                                                required
                                                name="ezPaymentName"
                                                placeholder="EZ Payment Name"
                                                control={control}
                                                options={EZPaymentMethods?.map((method) => {
                                                    return { label: method, value: method };
                                                })}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="ezStockAssetId"
                                                placeholder="EZ Stock Asset Id"
                                                control={control}
                                                style={{ width: "100%" }}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CustomInput
                                                required
                                                name="ezGuestID"
                                                placeholder="EZ Guest ID"
                                                control={control}
                                                style={{ width: "100%" }}
                                            />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="error" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit">
                                Submit
                            </Button>
                        </DialogActions>
                    </Box>
                </form>
            </Modal>
        </>
    );
};

export default LocationAdd;
