const Reducer = (state = {header: ""}, action: any) => {
    switch (action.type) {
        case 'set_header': 
            return {
                ...state,
                header: action.header
            };
        default: 
            return state;
    }
};

export default Reducer;