import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./theme/ThemeProvider";
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from 'redux';
import Reducer from "./store/reducers";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const router = createBrowserRouter(routes);
const store = createStore(Reducer);

router.subscribe((state) => store.dispatch({type:"set_header", header:""}));

root.render(
    <>
        <Provider store={store}>
            <ThemeProvider>
                <RouterProvider router={router} />
            </ThemeProvider>
        </Provider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
