import { MQTTProvider } from "../../mqtt";
import MachineList from "./MachineList";

const Machine = () => {
    return (
        <MQTTProvider>
            <MachineList />
        </MQTTProvider>
    );
};

export default Machine;
