import { API } from "../api/httpClient";
import {
    IMachineAddCommand,
    IMachinePositionFreeStateCommand,
    IMachinePositionTypeCommand,
    IMachineUpdateCommand,
    IMachinePositionDisabledStateCommand
} from "../interfaces/IMachine";

export const machineService = {
    getMachines: async () => {
        return await API.get(`/machines`);
    },
    addMachine: async (machineAddCommand: IMachineAddCommand) => {
        return await API.post(`/machines`, machineAddCommand);
    },
    updateMachine: async (machineUpdateCommand: IMachineUpdateCommand) => {
        return await API.put(`/machines/${machineUpdateCommand.machineId}/profile`, machineUpdateCommand);
    },
    getMachinePositions: async (machineId: number) => {
        return await API.get(`/machines/${machineId}/positions`);
    },
    changeMachinePositionFreeState: async (machinePositionFreeStateCommand: IMachinePositionFreeStateCommand) => {
        return await API.put(
            `/machines/${machinePositionFreeStateCommand.machineId}/position-free-state`,
            machinePositionFreeStateCommand
        );
    },
    changeMachinePositionType: async (machinePositionTypeCommand: IMachinePositionTypeCommand) => {
        return await API.put(
            `/machines/${machinePositionTypeCommand.machineId}/position-type`,
            machinePositionTypeCommand
        );
    },
    changeMachinePositionDisabledState: async (machinePositionDisabledStateCommand: IMachinePositionDisabledStateCommand) => {
        return await API.put(
            `/machines/${machinePositionDisabledStateCommand.machineId}/position-disabled-state`,
            machinePositionDisabledStateCommand
        );
    },
};
