import { useEffect, useState } from "react";
import { Card, Container, Grid, Link, Stack, Typography } from "@mui/material";
import CustomTable, { IColumn } from "../../components/CustomTable";
import { IMachine } from "../../interfaces/IMachine";
import { machineService } from "../../services/machineService";
import MachineAdd from "./MachineAdd";
import PageTitleWrapper from "../../components/PageTitleWrapper";
import moment from "moment";
import { IMQTTState, Topics, useMQTTController } from "../../mqtt";
import CustomTag from "../../components/CustomTag";

const MachineList = () => {
    const [controller, dispatch] = useMQTTController();
    console.log("controller: ", controller);

    const [machines, setMachines] = useState<Array<IMachine>>([]);
    const [normalMachineNumbers, setNormalMachineNumbers] = useState<Array<string>>();

    useEffect(() => {
        const { topic, message } = (controller || {}) as IMQTTState;
        if (topic === Topics.NOTICE_MACHINE_STATE) {
            (dispatch as React.Dispatch<any>)({ type: "clear" });
            const instruct = message ? JSON.parse(message) : {};
            console.log("instruct: ", instruct);
            setNormalMachineNumbers(instruct);
        }
    }, [controller]);

    useEffect(() => {
        getMachines();
    }, []);

    const getMachines = async () => {
        const result = await machineService.getMachines();
        if (result?.code === 200) {
            setMachines(result?.value);
        }
    };

    const refresh = () => {
        getMachines();
    };

    const columns: Array<IColumn> = [
        {
            title: "Action",
            width: 80,
            render(data) {
                return (
                    <Stack direction="row" spacing={2}>
                        <Link href={`/admin/machine/view/${data?.id}`}>View</Link>
                        <MachineAdd isEdit machine={data} refresh={refresh} />
                    </Stack>
                );
            },
        },
        {
            title: "Machine Number",
            dataKey: "machineNumber",
            width: 130,
        },
        {
            title: "Location Name",
            dataKey: "locationName",
            width: 200,
        },
        {
            title: "Machine State",
            width: 120,
            render(data) {
                const state =
                    normalMachineNumbers &&
                    (normalMachineNumbers?.find((item) => String(item) === String(data?.machineNumber))
                        ? "Working"
                        : "Lost");
                return <>{state && <CustomTag label={state} color={state === "Working" ? "Green" : "Red"} />}</>;
            },
        },
        {
            title: "State",
            width: 100,
            render(data) {
                const state = data.isDisabled?"Disabled/Maintaining":"Available"
                return <>{state && <CustomTag label= {state} color={!data.isDisabled ? "Green" : "Gray"} />}</>;
            },
        },
        {
            title: "Payment Device Id",
            dataKey: "paymentDeviceId",
            width: 150,
        },
        {
            title: "Mac Address",
            dataKey: "macAddress",
            width: 150,
        },
        // {
        //     title: "Ip Address",
        //     dataKey: "ipAddress",
        //     width: 150,
        // },
        // {
        //     title: "Software Version",
        //     dataKey: "softwareVersion",
        // },
        {
            title: "Last Replenishment Time",
            width: 180,
            render: (data) => {
                const date = moment.utc(data.lastReplenishmentDateTime || "");
                return (
                    <>
                        {date.isValid()
                            ? date.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Last Replenishment Clerk Name",
            dataKey: "lastReplenishmentClerkName",
            width: 250,
        },
        // {
        //     title: "Create User",
        //     dataKey: "createUser",
        // },
        // {
        //     title: "Create Date",
        //     dataKey: "createDateTime",
        // },
        // {
        //     title: "Last Update User",
        //     dataKey: "lastUpdateUser",
        // },
        // {
        //     title: "Last Update Date",
        //     dataKey: "lastUpdateDateTime",
        // },
    ];

    return (
        <>
            <PageTitleWrapper>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            Machines
                        </Typography>
                        {/* <Typography variant="subtitle2">These are your recent transactions</Typography> */}
                    </Grid>
                    <Grid item>
                        <MachineAdd refresh={refresh} />
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <div className="horizontal-scroll-table">
                                <CustomTable columns={columns} dataSource={machines} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default MachineList;
