import { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

interface IProps {
    name: string;
    placeholder?: string;
    required?: boolean;
    control: any;
    style?: React.CSSProperties;
    type?: "email" | "phoneNumber";
    disabled?: boolean;
    multiline?: boolean;
    rows?: number;
    startAdornment?: string;
    endAdornment?: string;
    inputType?: string;
    min?: number;
    max?: number;
    precision?: number;
    onInputChange?: (e: any) => void;
    onError?: (name: string, error: boolean, message?: string) => void;
}

const CustomInput = ({
    name,
    placeholder,
    required,
    control,
    style,
    type,
    disabled,
    multiline,
    rows,
    startAdornment,
    endAdornment,
    inputType = "text",
    min,
    max,
    precision,
    onInputChange,
    onError,
}: IProps) => {
    const [isError, setIsError] = useState<boolean>(false);

    const onTextFieldChange = (onChange: any, e: any) => {
        const value = e?.target?.value;
        if (type === "email") {
            const isNotEmail = !isEmail(value);
            setIsError(isNotEmail);
            onError && onError(name, isNotEmail, "is not email");
        } else if (type === "phoneNumber") {
            const isNotPhone = !isMobilePhone(value);
            setIsError(isNotPhone);
            onError && onError(name, isNotPhone, "is not phone");
        }

        if (inputType === "number") {
            if (typeof precision !== "undefined") {
                const num = value.split(".");
                if (num[1] && num[1].length > 2) {
                    const decimal = Math.pow(10, precision);
                    e.target.value = Math.floor(Number(value) * decimal) / decimal;
                }
            }
            if (typeof min !== "undefined" && Number(value) < min) {
                setIsError(true);
                onError && onError(name, true, `cannot be less than ${min}`);
            } else if (typeof max !== "undefined" && Number(value) > max) {
                setIsError(true);
                onError && onError(name, true, `cannot be greater than ${max}`);
            } else {
                setIsError(false);
                onError && onError(name, false, "normal");
            }
        }
        onChange(e);
        onInputChange && onInputChange(e);
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => {
                    const strValue = value || "";
                    return (
                        <TextField
                            error={isError}
                            required={required}
                            autoComplete="off"
                            label={placeholder}
                            disabled={disabled}
                            multiline={multiline}
                            rows={rows}
                            onChange={onTextFieldChange.bind(null, onChange)}
                            value={inputType === "number" ? value : strValue}
                            style={style}
                            inputProps={{ type: inputType, precision: 2 }}
                            InputProps={{
                                startAdornment: startAdornment && (
                                    <InputAdornment position="start">{startAdornment}</InputAdornment>
                                ),
                                endAdornment: endAdornment && (
                                    <InputAdornment position="start" style={{ marginRight: 0 }}>
                                        {endAdornment}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    );
                }}
            />
        </>
    );
};

export default CustomInput;
