import { IMachinePositionCellCanister } from "../../../interfaces/IMachine";
import { cellCanisterNumber, changeMachinePositionFreeState, layerCanisterNumber } from "./operateUtil";

const operateUnloadingShelves = {
    save: (command: any) => {
        return changeMachinePositionFreeState({ ...command, isFree: true });
    },
    setCanister: (
        canister: IMachinePositionCellCanister,
        checked: boolean,
        originCanister: IMachinePositionCellCanister
    ) => {
        canister.isEmpty = checked ? true : originCanister?.isEmpty;
        return canister;
    },
    setSingleCanister: (canister: IMachinePositionCellCanister, originCanister: IMachinePositionCellCanister) => {
        canister.isEmpty = !canister.isEmpty ? true : originCanister?.isEmpty;
        return canister;
    },
    getLayerCheck: (layerNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter((canister) => canister.layoutNumber === layerNumber && canister.isEmpty).length ===
            layerCanisterNumber
        );
    },
    getCellCheck: (layerNumber: number, cellNumber: number, canisters: Array<IMachinePositionCellCanister>) => {
        return (
            canisters.filter(
                (canister) =>
                    canister.layoutNumber === layerNumber && canister.cellNumber === cellNumber && canister.isEmpty
            ).length === cellCanisterNumber
        );
    },
};

export default operateUnloadingShelves;
