import moment from "moment";
import { Card, Container, Grid, Stack, Typography } from "@mui/material";
import PageTitleWrapper from "../../components/PageTitleWrapper";
import CustomTable, { IColumn } from "../../components/CustomTable";
import InsHoldCanisterEdit from "./InsHoldCanisterEdit";
import { insHoldCanisterService } from "../../services/insHoldCanisterService";
import { InsHoldCanister } from "../../interfaces/InsHoldCanister";
import { useEffect, useState } from "react";

const InsHoldCanisters = () => {
    const [canisters, setCanisters] = useState<Array<InsHoldCanister>>([]);

    useEffect(() => {
        getInsHoldCanisters();
    }, []);

    const getInsHoldCanisters = async () => {
        const result = await insHoldCanisterService.getInsHoldCanisters();
        if (result?.code === 200) {
            setCanisters(result?.value);
        }
    };

    const refresh = () => {
        getInsHoldCanisters();
    };

    const columns: Array<IColumn> = [
        {
            title: "Action",
            width: 80,
            render(data) {
                return (
                    <Stack direction="row" spacing={2}>
                        <InsHoldCanisterEdit insHoldCanister={data} refresh={refresh} />
                    </Stack>
                );
            },
        },
        {
            title: "Order Number",
            dataKey: "orderId",
            width: 100,
        },
        {
            title: "Canister ID",
            dataKey: "canisterId",
            width: 100,
        },
        {
            title: "Location",
            dataKey: "returnedLocationName",
            width: 150,
        },
        {
            title: "State",
            dataKey: "canisterState",
            width: 80,
        },
        {
            title: "Rented Date",
            width: 160,
            render: (data) => {
                const date = moment.utc(data.rentedDate || "");
                return (
                    <>
                        {date.isValid()
                            ? date.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Returned Date",
            width: 160,
            render: (data) => {
                const date = moment.utc(data.returnedDate || "");
                return (
                    <>
                        {date.isValid()
                            ? date.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                            : ""}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <PageTitleWrapper>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            InsHold Canisters
                        </Typography>
                        {/* <Typography variant="subtitle2">These are your recent transactions</Typography> */}
                    </Grid>
                    <Grid item>
                        <div style={{ marginBottom: "20px", height: "40px" }}></div>
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CustomTable columns={columns} dataSource={canisters} />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default InsHoldCanisters;
