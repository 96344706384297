import {
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

interface IProps {
    columns: Array<IColumn>;
    dataSource: Array<any>;
}
export interface IColumn {
    title: string;
    dataKey?: string;
    width?: number;
    render?: (data: any) => React.ReactNode;
}

const CustomTable = ({ columns, dataSource }: IProps) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#f5f5f5",
            border: "1px solid #eeeeee",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "1px solid #eeeeee",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(even)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:nth-of-type(odd)": {
            backgroundColor: "#ffffff",
        },
    }));

    return (
        <>
            <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns?.map((col, key) => {
                                return (
                                    <StyledTableCell key={key} width={col.width}>
                                        <div style={{ minWidth: col.width }}>{col.title}</div>
                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource?.map((item, key) => (
                            <StyledTableRow key={key}>
                                {columns?.map((col) => {
                                    return (
                                        <StyledTableCell key={Math.random()}>
                                            {col.dataKey && item[col.dataKey]}
                                            {col.render && col.render(item)}
                                        </StyledTableCell>
                                    );
                                })}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default CustomTable;
