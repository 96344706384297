import { TextField } from "@mui/material";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";

interface IProps {
    name: string;
    placeholder?: string;
    control: any;
    className?: string;
    onDateChange?: (value: any) => void;
    maxDate?: any;
    minDate?: any;
}

const CustomDateTimePicker = ({ name, placeholder, control, className, onDateChange, maxDate, minDate }: IProps) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDateTimePicker
                            className={className}
                            label={placeholder}
                            inputFormat="MM/DD/YYYY hh:mm a"
                            value={value || null}
                            onChange={(value: any) => {
                                onChange(value);
                                onDateChange && onDateChange(value);
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            renderInput={(params) => <TextField {...params} />}
                            InputProps={{ autoComplete: "off" }}
                        />
                    </LocalizationProvider>
                )}
            />
        </>
    );
};

export default CustomDateTimePicker;
