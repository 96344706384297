import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Card, Container, Grid, Stack, Typography } from "@mui/material";
import CustomTable, { IColumn } from "../../components/CustomTable";
import PageTitleWrapper from "../../components/PageTitleWrapper";
import { IUser } from "../../interfaces/IUser";
import { userService } from "../../services/userService";
import UserAdd from "./UserAdd";
import UserDelete from "./UserDelete";
import moment from "moment";

const User = () => {
    const cookies = new Cookies();
    const userInfo = cookies.get("userInfo");

    const [users, setUsers] = useState<Array<IUser>>([]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        const result = await userService.getUsers();
        if (result?.code === 200) {
            setUsers(result?.value);
        }
    };

    const refresh = () => {
        getUsers();
    };

    const columns: Array<IColumn> = [
        {
            title: "User Name",
            width: 130,
            render(data) {
                return (
                    <>
                        {data?.firstName} {data?.lastName}
                    </>
                );
            },
        },
        // {
        //     title: "Gender",
        //     dataKey: "gender",
        //     width: 80,
        // },
        // {
        //     title: "Date Of Birth",
        //     dataKey: "dateOfBirth",
        //     width: 130,
        // },
        {
            title: "Phone Number",
            dataKey: "phoneNumber",
            width: 130,
        },
        {
            title: "Email",
            dataKey: "email",
            width: 150,
        },
        {
            title: "Last Login Time",
            dataKey: "lastLoginTime",
            width: 150,
            render: (data) => {
                const date = moment.utc(data.lastLoginDateTime || "");
                return (
                    <>
                        {date.isValid()
                            ? date.utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")
                            : ""}
                    </>
                );
            },
        },
    ];

    userInfo?.role === "Admin" &&
        columns.splice(0, 0, {
            title: "Action",
            width: 130,
            render(data) {
                return (
                    <Stack direction="row" spacing={2}>
                        <UserAdd isEdit userId={data?.id} refresh={refresh} />
                        <UserDelete userId={data?.id} refresh={refresh} />
                    </Stack>
                );
            },
        });

    return (
        <>
            <PageTitleWrapper>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            Users
                        </Typography>
                        {/* <Typography variant="subtitle2">These are your recent transactions</Typography> */}
                    </Grid>
                    <Grid item>{userInfo?.role === "Admin" && <UserAdd refresh={refresh} />}</Grid>
                </Grid>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            {/* <CardHeader title="User List" />
                            <Divider /> */}
                            <CustomTable columns={columns} dataSource={users} />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default User;
