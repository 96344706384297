import Mockjs from "mockjs";
import { API } from "../api/httpClient";
import { InsHoldCanisterEditCommand } from "../interfaces/InsHoldCanister";

export const insHoldCanisterService = {
    getInsHoldCanisters: async () => {
        return await API.get(`/orders/inshold-canisters`);
        // return await Mockjs.mock({
        //     code: 200,
        //     "value|20": [
        //         {
        //             orderId: 0,
        //             itemId: 0,
        //             canisterId: 0,
        //             returnedLocationName: "string",
        //             canisterState: "string",
        //             rentedDate: "2023-03-30T09:20:03.762Z",
        //             returnedDate: "2023-03-30T09:20:03.762Z",
        //         },
        //     ],
        // });
    },
    updateInsHoldCanister: async (command: InsHoldCanisterEditCommand) => {
        return await API.put(`/orders/${command.orderId}/canisters/${command.canisterId}`, command);
        // return await Mockjs.mock({
        //     code: 200,
        // });
    },
};
