import ReactDOM from "react-dom";
import { Alert, AlertColor, Snackbar } from "@mui/material";

const CustomAlert = (severity: AlertColor, message: string) => {
    const div = document.createElement("div");
    ReactDOM.render(
        <>
            <Snackbar
                open={true}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => {
                    try {
                        document.body.removeChild(div);
                    } catch (e) {
                        // console.log(e);
                    }
                }}
            >
                <Alert variant="filled" severity={severity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>,
        div
    );
    document.body.appendChild(div);
};

export default CustomAlert;
