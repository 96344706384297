import { API } from "../api/httpClient";
import { ILoginCommand, IUserAddCommand, IUserChangePasswordCommand, IUserUpdateCommand } from "../interfaces/IUser";

export const userService = {
    getUsers: async () => {
        return await API.get(`/users`);
    },
    getUser: async (userId: number) => {
        return await API.get(`/users/${userId}`);
    },
    addUser: async (userAddCommand: IUserAddCommand) => {
        return await API.post(`/users`, userAddCommand);
    },
    updateUser: async (userUpdateCommand: IUserUpdateCommand) => {
        return await API.put(`/users/${userUpdateCommand.id}`, userUpdateCommand);
    },
    deleteUser: async (userId: number) => {
        return await API.delete(`/users/${userId}`);
    },
    changePassword: async (userChangePasswordCommand: IUserChangePasswordCommand) => {
        return await API.put(`/users/${userChangePasswordCommand.userId}/password`, userChangePasswordCommand);
    },
    login: async (loginCommand: ILoginCommand) => {
        return await API.post(`/login`, loginCommand);
    },
};
