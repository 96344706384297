import { useContext } from "react";
import { Box, alpha, Stack, lighten, Divider, IconButton, Tooltip, styled, useTheme, Typography } from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import HeaderButtons from "./Buttons";
import HeaderUserbox from "./Userbox";
import HeaderMenu from "./Menu";
import { SidebarContext } from "../../../contexts/SidebarContext";
import SidebarMenu from "../Sidebar/SidebarMenu";
import { useSelector } from 'react-redux';

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background || "", 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const theme = useTheme();
    const header = useSelector( (state: any) => state.header);
    return (
        <HeaderWrapper
            display="flex"
            alignItems="center"
            sx={{
                boxShadow:
                    theme.palette.mode === "dark"
                        ? `0 1px 0 ${alpha(
                              lighten(theme.colors.primary.main, 0.7),
                              0.15
                          )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                        : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(
                              theme.colors.alpha.black[100],
                              0.1
                          )}`,
            }}
        >
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                alignItems="center"
                spacing={2}
            >
                {/* <HeaderMenu /> */}
            </Stack>
            <Box display="flex">
                <Typography variant="h3" component="h3" gutterBottom>
                    {header}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center">
                {/* <HeaderButtons /> */}
                <HeaderUserbox />
                <Box
                    component="span"
                    sx={{
                        ml: 2,
                        display: { lg: "none", xs: "inline-block" },
                    }}
                >
                    <Tooltip arrow title={<SidebarMenu />}>
                        <IconButton color="primary">
                            <MenuTwoToneIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </HeaderWrapper>
    );
}

export default Header;
