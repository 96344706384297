import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

interface IProps {
    name: string;
    placeholder?: string;
    required?: boolean;
    control: any;
    style?: React.CSSProperties;
    options?: Array<IOption>;
    disabled?: boolean;
    onSelectChange?: (event: any) => void;
}

interface IOption {
    value: any;
    label: any;
}

const CustomSelect = ({
    name,
    placeholder,
    required,
    control,
    disabled,
    style,
    options = [],
    onSelectChange,
}: IProps) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth required={required}>
                        <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disabled={disabled}
                            value={value}
                            label={placeholder}
                            onChange={(event: any) => {
                                onChange(event);
                                onSelectChange && onSelectChange(event);
                            }}
                            style={style}
                        >
                            {options?.map((option, key) => {
                                return (
                                    <MenuItem key={key} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            />
        </>
    );
};

export default CustomSelect;
