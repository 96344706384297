import { errorAlert, successAlert } from "../../../components/CustomModal";
import { IMachinePositionFreeStateCommand, IMachinePositionTypeCommand, IMachinePositionDisabledStateCommand } from "../../../interfaces/IMachine";
import { machineService } from "../../../services/machineService";
import operateLoadingShelves from "./operateLoadingShelves";
import operateSetDamagedUsedArea from "./operateSetDamagedUsedArea";
import operateSetNormalArea from "./operateSetNormalArea";
import operateSetReturnArea from "./operateSetReturnArea";
import operateUnloadingShelves from "./operateUnloadingShelves";
import operateEnablePosition from "./operateEnablePosition";
import operateDisablePosition from "./operateDisablePosition";

export enum OPERATE {
    LOADING_SHELVES = "Loading Shelves",
    UNLOADING_SHELVES = "Unloading Shelves",
    SET_DAMAGED_OR_USED_AREA = "Set Damaged/Used Position",
    SET_RETURN_AREA = "Set Return Position",
    SET_NORMAL_AREA = "Set Normal Position",
    ENABLE_POSITION = "Enable Position",
    DISABLE_POSITION = "Disable Position",
}
export enum CANISTER_TYPE {
    NORMAL = "Normal",
    DAMAGED_USED = "DamagedUsed",
    RETURN = "Return",
}
export const layerCanisterNumber = 40;
export const cellCanisterNumber = 10;

export const operateObj = {
    [OPERATE.LOADING_SHELVES]: operateLoadingShelves,
    [OPERATE.UNLOADING_SHELVES]: operateUnloadingShelves,
    [OPERATE.SET_NORMAL_AREA]: operateSetNormalArea,
    [OPERATE.SET_DAMAGED_OR_USED_AREA]: operateSetDamagedUsedArea,
    [OPERATE.SET_RETURN_AREA]: operateSetReturnArea,
    [OPERATE.ENABLE_POSITION]: operateEnablePosition,
    [OPERATE.DISABLE_POSITION]: operateDisablePosition,
};

export const changeMachinePositionFreeState = async (
    machinePositionFreeStateCommand: IMachinePositionFreeStateCommand
) => {
    const result = await machineService.changeMachinePositionFreeState(machinePositionFreeStateCommand);
    if (result?.code === 200 && result.value) {
        successAlert("Succeeded in changing machine position!");
        return true;
    } else {
        errorAlert("Failed in changing machine position!");
        return false;
    }
};

export const changeMachinePositionDisabledState = async (
    machinePositionDisabledStateCommand: IMachinePositionDisabledStateCommand
) => {
    const result = await machineService.changeMachinePositionDisabledState(machinePositionDisabledStateCommand);
    if (result?.code === 200 && result.value) {
        successAlert("Succeeded in changing machine position!");
        return true;
    } else {
        errorAlert("Failed in changing machine position!");
        return false;
    }
};

export const changeMachinePositionType = async (machinePositionTypeCommand: IMachinePositionTypeCommand) => {
    const result = await machineService.changeMachinePositionType(machinePositionTypeCommand);
    if (result?.code === 200 && result.value) {
        successAlert("Succeeded in changing machine position!");
        return true;
    } else {
        errorAlert("Failed in changing machine position!");
        return false;
    }
};